import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import inputRules from './rules.js';
import pagninate from './components/pagninate.vue';
import searchAutocomplete from './components/search_autocomplete.vue';

Vue.component('pagninate', pagninate);
Vue.component('search-autocomplete', searchAutocomplete);
Vue.config.productionTip = false
Vue.mixin(inputRules);

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
