import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import loading from "./modules/loading";
import products from "./modules/products";
import errors from "./modules/errors";
import users from "./modules/users";

import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  //storing session state incase user refreshes page user token is not lost
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  modules: {
    auth,
    loading,
    products,
    errors,
    users,
  },
});
