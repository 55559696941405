import axios from "axios";
import generalMethods from "@/rules.js";


const state = {
  products: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  searchResults: [],
  selectedSearchResult: {},
};
const getters = {
  productsPerPage(state) {
    return state.products.per_page;
  },
  allProducts(state) {
    for (let i = 0; i < state.products.data.length; i++) {
      state.products.data[i]['updated_at'] = generalMethods.methods.formatFriendlyDate(state.products.data[i]['updated_at']);
    }
    return state.products.data;
  },
  selectedSearchResult(state) {
    return state.selectedSearchResult;
  },
};

const actions = {
  //crud operations
  async addProduct({ commit, rootGetters, dispatch }, product) {
    commit("loading/loading", true, { root: true });
    let form = new FormData();
    form.append("name", product.name);
    form.append("image", product.file);
    form.append("description", product.description);
    form.append("price", product.price);
    await axios
      .post(process.env.VUE_APP_API + "products/setProduct", form, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        dispatch("fetchItems", { search: '', perPage: '', pageNumber: '' });
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        product.close();
      })
      .catch((error) => {
        dispatch('errors/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
      });
  },
  async fetchItems(
    { commit, rootGetters, dispatch },
    { search, perPage, pageNumber }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "products/getProducts", {
        params: {
          page: pageNumber,
          perPage: perPage,
          search: search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setProducts", response.data);
      })
      .catch((error) => {
        dispatch('errors/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
      });
  },
  async updateProduct({ commit, rootGetters, dispatch }, product) {
    commit("loading/loading", true, { root: true });
    let form = new FormData();
    form.append("id", product.id);
    form.append("name", product.name);
    form.append("description", product.description);
    form.append("price", product.price);
    form.append("image", product.file);
    await axios
      .post(process.env.VUE_APP_API + "products/updateProduct", form, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        product.close();
        dispatch("fetchItems", { search: '', perPage: '', pageNumber: '', selected: '' });
      })
      .catch((error) => {
        dispatch('errors/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
      });
  },

  async deleteProduct({ commit, rootGetters, dispatch }, id) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "products/deleteProduct/" + id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("deleteProduct", id);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('errors/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
      });
  },
};

const mutations = {
  //crud mutations
  setProducts(state, products) {
    state.products = products;
  },

  deleteProduct(state, id) {
    state.products.data = state.products.data.filter(
      (product) => product.id !== id
    );
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  // setters
  setCurrentPage(state, page) {
    state.products.current_page = page;
  },
  setPerPage(state, perPage) {
    state.products.per_page = perPage;
  },
  selectedSearchResult(state, obj) {
    state.selectedSearchResult = obj;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
