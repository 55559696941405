import router from '@/router';
import generalMethods from "@/rules.js";

const state = {
};
const getters = {

};
const actions = {
    handleErrors({ dispatch }, error) {
        if (error.response && error.response.data && "error" in error.response.data) {
          generalMethods.methods.executeAlert({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error,
          });
        } else {
          generalMethods.methods.executeAlert({
            icon: 'error',
            title: 'Error',
            text: "Something went wrong",
          });
        }
    
        if (error.response && 'exit' in error.response.data && error.response.data.exit == 1) {
          dispatch('auth/signout',null,{root:false});
        }
    
        if (error.response && (error.response.status === 401 || error.response.status === 403) && !('exit' in error.response.data)) {
          dispatch('forbiddenError');
        }
      },
      
      forbiddenError({ commit, rootState }) {
        commit("auth/setAuthenticated", false,{root:true});
        router.push("/");
        commit("auth/clearState", rootState,{root:true});
        generalMethods.methods.executeAlert({
          icon: 'error',
          title: 'Error',
          text: "Something went wrong re-authenticate"
        });
      }
  
};
const mutations = {
};

export default {
  namespaced:true,
  state,
  getters,
  actions,
  mutations,
};
