import axios from "axios";
import router from "@/router";
import generalMethods from "@/rules.js";


const state = {
  user: {
    id: "",
    username: "",
    createdby: "",
    status: "",
  },
  token: "",

  authenticated: false,
};
const getters = {
  user(state) {
    return state.user;
  },

  userName(state) {
    return state.user.name;
  },
  authenticated(state) {
    return state.authenticated;
  },

  token(state) {
    return state.token;
  },
};
const actions = {
  async signup({ commit, rootGetters, dispatch }, user) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(
        process.env.VUE_APP_API + "user/signup",
        {
          createdby: rootGetters["auth/user"].id,
          username: user.username,
          password: user.password,
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('errors/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
      });
  },
  async signin({ commit, dispatch }, user) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(process.env.VUE_APP_API + "signin", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        commit("setUser", response.data);
        commit("setAuthenticated", true);
        router.push("/products");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('errors/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  signout({ commit, getters, rootState, dispatch }) {
    commit("loading/loading", true, { root: true });
    axios
      .get(process.env.VUE_APP_API + "signout", {
        headers: {
          Authorization: "Bearer " + getters.token,
          "x-user-id": getters.user.id,
        },
      })
      .then((response) => {
        commit("setAuthenticated", false);
        router.push("/");
        commit("clearState", rootState);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('errors/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
};
const mutations = {
  setUser(state, data) {
    state.user = data.user;
    state.token = data.token;
  },

  setAuthenticated(state, value) {
    state.authenticated = value;
  },

  clearState(state, rootState) {
    // Clear user module rootStates
    state.user = { user: { name: "" } };

    // Clear users module rootStates
    rootState["users"]["users"] = {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };

    rootState["users"]["searchResults"] = [];
    rootState["users"]["selectedSearchResult"] = {};


    // Clear projects module rootStates
    rootState["products"]["products"] = {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };

    rootState["products"]["searchResults"] = [];
    rootState["products"]["selectedSearchResult"] = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
