import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

// Use VueSweetalert2 globally
Vue.use(VueSweetalert2)

const rules = {
  computed: {
    emailRules() {
      return [
        (v) => !!v || "Enter email",
        (v) => /.+@.+\..+/.test(v) || "Email entered is not valid",
      ];
    },
    mobileRules() {
      return [
        (v) => !!v || "Enter Mobile",
        (v) => (v && v.length == 12) || "Mobile isn't valid",
      ];
    },
    generalRules() {
      return [(v) => !!v || "Field is empty"];
    },
    yesNoRules() {
      return [
        (v) => {
          if (v === 0 || v === 1) {
            return true;
          }
          return "Value must be either 1 or 0";
        },
      ];
    },
    passwordRules() {
      return [(v) => !!v || "Password required"];
    },
    rePassRules() {
      return [
        (v) => !!v || "Re-enter password",
        (v) => this.user.password == v || "Passwords don't match",
      ];
    },
    textAreaRules() {
      return [
        (v) => !!v || "Field is empty",
        (v) => (v && v.length <= 200) || "Max 200 characters",
      ];
    },
    customerRePass() {
      return [
        (v) => !!v || "Re-enter password",
        (v) => this.editedItem.password == v || "Passwords don't match",
      ];
    },
  },
  methods: {
    formatWithCommas(number) {
      const parsedNumber = parseFloat(number); // Convert the string to a number
      if (isNaN(parsedNumber)) {
        return number; // Return the input if it's not a valid number
      }
      return parsedNumber.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });
    },
    formatFriendlyDate(dateString) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };

      const parsedDate = new Date(dateString);
      const formattedDate = parsedDate.toLocaleDateString("en-US", options);
      return formattedDate;
    },
    formatFriendlyDateOnly(dateString) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
    
      const parsedDate = new Date(dateString);
      const formattedDate = parsedDate.toLocaleDateString("en-US", options);
      return formattedDate;
    },
   capitalizeWords(str) {
      if (str === null || str === undefined) {
        return str;
      }
    
      return str.replace(/\b\w/g, function(match) {
        return match.toUpperCase();
      });
    },
    executeAlert({ icon = '', title = '', text = '' }) {
      Vue.swal({
        icon: icon,
        title: title,
        text: text,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Vue.swal.stopTimer;
          toast.onmouseleave = Vue.swal.resumeTimer;
        }
      });
    }
  },
};
export default rules;
