import Vue from 'vue'
import VueRouter from 'vue-router'
import signin from "../views/signin.vue";
import store from "@/store";
import generalMethods from '@/rules';

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "signin",
    component: signin,
  },
  {
    path: "/products",
    name: "products",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/products.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/users",
  //   name: "Admin Panel Users",
  //   component: () =>
  //     import(/* webpackChunkName: "users" */ "../views/users.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },

]

const router = new VueRouter({
  routes
});

// Define the handleBackButton function
const handleBackButton = () => {
  router.beforeEach((to, from, next) => {
    if (window.event.type === 'popstate' && from.name === 'signin') {
      next(false);
    } else {
      next();
    }
  });
};
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/authenticated"]) {
      next();
    } else {
      next({ name: "signin" });
      generalMethods.methods.executeAlert({
        icon: "error",
        title: "Error",
        text: "You need to be authenticated.",
      });
    }
  } else {
    if (store.getters["auth/authenticated"] && (to.name === 'signin' || to.name === 'signup')) {
      next({ name: "products" });
      generalMethods.methods.executeAlert({
        icon: "info",
        title: "Info",
        text: "You are already authenticated.",
      });
    } else {
      next();
    }
  }
});

export default router
