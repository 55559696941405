<template>
  <v-card :width="$vuetify.breakpoint.mobile?'90%':'50%'" class="mx-auto" flat color="transparent">
    <v-card-text>
      <v-pagination v-model="page" :length="lastPage" circle color="#1e577a"></v-pagination>
    </v-card-text>
    <v-card :width="$vuetify.breakpoint.mobile?'50%':'30%'" flat color="transparent" class="mx-auto">
    <v-card-text>
      <v-select
        v-model="currentPerPage"
        :items="perPageItems"
        class="text-center"
        label="Items Per Page"
        color="#1e577a"
      ></v-select>
    </v-card-text>
  </v-card>
  </v-card>
</template>

<script>
export default {
  props: ["store", "collection", "search"],
  data() {
    return {
      perPageItems: [5, 10, 15, 20, 25, 30],
      currentPerPage: 10,
      page: 1,
      totalPages: 1,
    };
  },

  computed: {
    currentPage: {
      get() {
        this.page = this.$store.state[this.store][this.collection].current_page;
      },
      set(val) {
        this.$store.commit(this.store + "/setCurrentPage", val);
      },
    },
    lastPage: {
      get() {
        return this.$store.state[this.store][this.collection].last_page;
      },
    },
    itemsPerPage: {
      get() {
        this.currentPerPage =this.$store.state[this.store][this.collection].per_page;
      },
      set(val) {
        this.$store.commit(this.store + "/setPerPage", val);
      },
    },
  },
  watch: {
    currentPerPage(newVal, oldVal) {
      if (newVal != oldVal){
        this.$store.dispatch(this.store + "/fetchItems", {
          search: this.search,
          perPage: newVal,
          pageNumber: 1,
        });
        this.page=1;
      }
    },
    page(newVal, oldVal) {
      if (newVal != oldVal)
        this.$store.dispatch(this.store + "/fetchItems", {
          search: this.search,
          perPage: this.currentPerPage,
          pageNumber: newVal,
        });
    },
  },
};
</script>

<style>
</style>