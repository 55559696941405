<template>
  <v-app>
    <sideNav v-if="show" />
    <v-main>
      <v-slide-x-transition mode="out-in">
      <router-view />
    </v-slide-x-transition>
    </v-main>
    <v-overlay :value="loading" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import sideNav from "@/components/side_nav.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({}),
  computed: {
    ...mapGetters({
      loading: "loading/loading",
    }),
    show() {
      if (this.$route.name == "signin") return false;
      else if (this.$route.name == "signup") return false;
      else if (this.$route.name != "signin" && this.$route.name != "signup")
        return true;
    },
  },
  components: { sideNav },
};
</script>
