<template>
  <v-card class="mx-auto" height="100%" flat>
    <v-card-text>
 <v-img src="@/assets/logo.svg" class="mx-auto"
                  lazy-src="@/assets/logo.svg" width="100%">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card-text>
    <v-card-text>
      <signinForm />
    </v-card-text>
  </v-card>
</template>

<script>
import signinForm from "./signinForm.vue";
export default {
  data() {
    return {};
  },
  components: { signinForm },
};
</script>

<style scoped>
</style>