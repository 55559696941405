
import axios from "axios";
import router from "@/router";
import generalMethods from "@/rules.js";


const state = {
  users: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  searchResults: [],
  selectedSearchResult: {},
};
const getters = {

  usersPerPage(state) {
    return state.users.per_page;
  },
  allUsers(state) {
    for (let i = 0; i < state.users.data.length; i++) {
      state.users.data[i]['updated_at'] = generalMethods.methods.formatFriendlyDate(state.users.data[i]['updated_at']);
    }
    return state.users.data;
  },
};
const actions = {
  async signup({ commit, rootGetters, dispatch }, user) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(
        process.env.VUE_APP_API + "user/signup",
        {
          createdby: rootGetters["auth/user"].id,
          username: user.user_name,
          password: user.password,
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('errors/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
      });
  },

  async fetchItems(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "user/getUsers", {
        params: {
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setUsers", response.data);
      })
      .catch((error) => {
        dispatch('errors/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
      });
  },
  async updateUser({ commit, rootGetters, dispatch }, user) {
    commit("loading/loading", true, { root: true });
    await axios
      .put(
        process.env.VUE_APP_API + "user/updateUser/" + user.id,
        {
          username: user.user_name,
          password: user.password,
          user_id: rootGetters["auth/user"].id,
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");

        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('errors/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
      });
  },
  async deleteUser({ commit, rootGetters, dispatch }, id) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "user/deleteUser/" + id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("deleteUser", id);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('errors/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
      });
  },

};
const mutations = {
  setUsers(state, Users) {
    state.users = Users;
  },

  deleteUser(state, id) {
    state.users.data = state.users.data.filter((user) => user.id !== id);
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
