import { render, staticRenderFns } from "./mobile.vue?vue&type=template&id=2c25f64a&scoped=true"
import script from "./mobile.vue?vue&type=script&lang=js"
export * from "./mobile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c25f64a",
  null
  
)

export default component.exports