<template>
  <v-row>
    <v-col cols="6">
      <v-card class="mx-auto" width="80%" height="100%" flat style="margin-top:20%">
        <signinForm />
      </v-card>
    </v-col>
    <v-col>
      <v-img
        src="@/assets/logo.svg"
        lazy-src="@/assets/logo.svg"
        width="100%"
        class="mx-auto"
        style="margin-top:20%"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
import signinForm from "./signinForm.vue";
export default {
  data() {
    return {};
  },
  components: { signinForm },
};
</script>

<style scoped>
</style>