import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto",attrs:{"flat":"","color":"transparent","width":"100%"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)}}},[_c(VForm,{ref:"signinForm"},[_c(VCardText,[_c(VTextField,{ref:"usernameInput",attrs:{"color":"black","label":"username","prepend-icon":"mdi mdi-account","rules":_vm.generalRules},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c(VTextField,{attrs:{"label":"Password","color":"black","prepend-icon":"mdi mdi-lock","append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"rules":_vm.passwordRules},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"mr-3 white--text",attrs:{"color":"#1e577a"},on:{"click":_vm.submit}},[_vm._v(" Signin ")]),_c(VBtn,{on:{"click":_vm.clear}},[_vm._v("clear")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }